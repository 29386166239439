export default [
  {
    path: '/points-de-vente/liste',
    name: 'points-de-vente-liste',
    component: () => import('@/views/afridia/points_de_vente/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Points de vente - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/points-de-vente/details/:id',
    name: 'points-de-vente-details',
    component: () => import('@/views/afridia/points_de_vente/details/MainVue.vue'),
    meta: {
      pageTitle: 'Point de vente - Details',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/points-de-vente/statistiques',
    name: 'points-de-vente-statistiques',
    component: () => import('@/views/afridia/points_de_vente/statistiques/MainVue.vue'),
    meta: {
      pageTitle: 'Points de vente - Statistiques',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
]
