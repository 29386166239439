export default [
  {
    path: '/distributeurs/liste',
    name: 'distributeurs-liste',
    component: () => import('@/views/afridia/distributeurs/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Distributeurs - Liste',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
    },
  },
  {
    path: '/distributeurs/details/:id',
    name: 'distributeurs-details',
    component: () => import('@/views/afridia/distributeurs/details/MainVue.vue'),
    meta: {
      pageTitle: 'Distributeur',
      breadcrumb: [
        {
          text: 'Infos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/distributeurs/visites',
    name: 'distributeurs-visites',
    component: () => import('@/views/afridia/distributeurs/visites/liste/MainVue.vue'),
    meta: {
      pageTitle: 'Distributeurs - Visites',
      breadcrumb: [
        {
          text: 'Visites',
          active: true,
        },
      ],
    },
  },
  {
    path: '/distributeurs/statistiques',
    name: 'distributeurs-statistiques',
    component: () => import('@/views/afridia/distributeurs/statistiques/MainVue.vue'),
    meta: {
      pageTitle: 'distributeurs - Statistiques',
      breadcrumb: [
        {
          text: 'Statistiques',
          active: true,
        },
      ],
    },
  },
]
