export default [
  {
    path: '/geolocalisations/carte',
    name: 'geolocalisations-carte',
    component: () => import('@/views/afridia/geolocalisations/carte/MainVue.vue'),
    meta: {
      pageTitle: 'Géolocalisations - Carte',
      breadcrumb: [
        {
          text: 'Carte',
          active: true,
        },
      ],
    },
  },
  {
    path: '/geolocalisations/recherche',
    name: 'geolocalisations-recherche',
    component: () => import('@/views/afridia/geolocalisations/recherche/MainVue.vue'),
    meta: {
      pageTitle: 'Géolocalisations - Recherche',
      breadcrumb: [
        {
          text: 'Recherche',
          active: true,
        },
      ],
    },
  },
]
