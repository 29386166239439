// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default function showToast(that, variant, title, text) {
  that.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon: 'CheckIcon',
      text,
      variant,
    },
  })
}

export function restObject(object, customValues = {}, excepts = {}) {
  Object.keys(object)
    .forEach(key => {
      // console.log(key, object[key])
      if (!excepts[key]) {
        // eslint-disable-next-line no-param-reassign
        object[key] = null
      }
      if (customValues[key]) {
        // eslint-disable-next-line no-param-reassign
        object[key] = customValues[key]
      }
    })
}

export function searchInObject(object, attribute, value) {
  let objectFind = null
  Object.keys(object)
    .forEach(key => {
      console.log(key, object[key])
      if (key === attribute && object[key] === value) {
        // eslint-disable-next-line no-param-reassign
        objectFind = object[key]
      }
    })
  return objectFind
}

export function firstDayOfMonth() {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function dateFormater(date) {
  if (date instanceof Date) {
    return date.toLocaleString('fr-FR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
    })
  }
  return new Date(date).toLocaleString('fr-FR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
    // second: 'numeric',
  })
}

export function nowDate() {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function formatDate(date) {
  if (!date) {
    return null
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  return `${day}/${month}/${year}`
}

export function myLogout(that) {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  // Reset ability
  that.$ability.update(initialAbility)

  // Redirect to login page
  that.$router.push({ name: 'login' })
}
