export default [
  {
    path: '/tableaux-de-bord/ventes',
    name: 'tableaux-de-bord-ventes',
    component: () => import('@/views/afridia/tableau_de_bord/main/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord',
      breadcrumb: [
        {
          text: '/',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/appros',
    name: 'tableaux-de-bord-appros',
    component: () => import('@/views/afridia/tableau_de_bord/appros/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Approvisionnements',
      breadcrumb: [
        {
          text: 'Approvisionnements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tableaux-de-bord/stocks',
    name: 'tableaux-de-bord-stocks',
    component: () => import('@/views/afridia/tableau_de_bord/stocks/MainVue.vue'),
    meta: {
      pageTitle: 'Tableaux de bord - Stocks',
      breadcrumb: [
        {
          text: 'Stocks',
          active: true,
        },
      ],
    },
  },
]
