// export const BASE_URL = 'http://127.0.0.1:8000'
// export const BASE_URL = 'http://api-afridia.gti-mali.com'
// export const API_URL = `http://127.0.0.1:8000/api/afridia/`
export const API_URL = 'http://api-afridia.gti-mali.com/api/afridia/'
export const HIDDEN_NAVBAR = false
export const HIDDEN_MENU = false
export const COLOR_PRIMARY = '#1E90FF'
export const OVERLAY_CONFIG = {
  variant: 'transparent',
  opacity: 0.90,
  blur: '2px',
  rounded: 'sm',
}
